import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// import StatusCodeS from '../images/statuscode-s-black-on-green-square.svg';
// import StatusCodeS from '../images/statuscode-s-white-on-black-square.svg';
import StatusCodeS from '../images/statuscode-s-green-on-black-square.svg';

const ScreenCustoms = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "form-study.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => Render(data)}
  />
)

const Render = (data) => {
  return <div className="screen-customs">
      <Img
        className="screen-customs__item"
        fluid={data.placeholderImage.childImageSharp.fluid} />
      <img
        className="screen-customs__item"
        src={StatusCodeS}
        alt="Status Code - Maatwerk voor ieder scherm"/>
  </div>

}

export default ScreenCustoms
