import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Image from "../components/image"
import ScreenCustoms from "../components/screen-customs"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Websites, mobiele applicaties, TV applicaties en andere digitale toepassingen"
      keywords={[
        `website`,
        `websites`,
        `webdevelopment`,
        `web development`,
        `ontwikkelaar`,
        `apps`,
        `mobile`,
        `mobiel`,
        `iOS`,
        `Android`,
        `AndroidTV`,
        `tvOS`,
        `api`,
        `frontend`,
        `backend`,
        `applicatie`,
        `react`,
      ]}
    />
    <article>
      <p>
        <strong>Status Code</strong> ontwikkelt digitale applicaties en helpt
        jou met het maken van de juiste keuzes in het ontwikkeltraject.
      </p>

      <p>
        Het begint allemaal met een goed idee en een goed verhaal. Hoe te
        vertellen, om mensen echt te laten begrijpen wat je bedoelt of wil, is
        essentieel voor je bedrijf. We kunnen je daarbij helpen: het juiste
        advies voor de juiste toepassing.
      </p>

      <p>
        Gebouwd door ontwikkelaars die in code denken, als een vriend luisteren
        en je met duidelijke oplossingen adviseren. Dat is wat wij{" "}
        <strong>digital well-being</strong> noemen.
      </p>

      <ScreenCustoms />

      <p>
        Status Code is een initiatief van{" "}
        <a href="https://martijnterhellen.nl/">Martijn ter Hellen</a>. Hij is
        ruim 20 jaar actief op het gebied van ontwikkeling van digitale
        applicaties en heeft veel ervaring in het bouwen van websites, mobiele
        applicaties, TV applicaties, Content Management systemen en andere
        maatwerk oplossingen.
      </p>

      <p>
        We werken voor klanten als{" "}
        <a href="https://www.evidentaal.nl">Evidentaal</a>,{" "}
        <a href="https://www.renofloorz.nl">Renofloorz</a>,{" "}
        <a href="https://www.scholenmetsucces.nl">Scholen met Succes</a>,{" "}
        <a href="https://beyonddutch.com">BeyondDutch</a> (voorheen{" "}
        <a href="https://dutchchannels.com">DutchChannels</a>),{" "}
        <a href="https://harddraverijverenigingheemskerk.nl">
          Harddraverij Heemskerk
        </a>
        , <a href="https://vsvmonitor.nl">VSV Monitor</a>,{" "}
        <a href="https://dasgezond.nl">Dasgezond</a>,{" "}
        <a href="https://www.meentboerderij.nl">De Meentboerderij</a>,{" "}
        <a href="https://www.borgerstraat.nl">Tandartspraktijk Borgerstraat</a>,{" "}
        <a href="https://kenzfigee.com/">KenzFigee</a>,{" "}
        <a href="https://getbksy.com/">Bksy</a>,{" "}
        <a href="https://www.hospitaalbroeders.nl/">HospitaalBroeders</a>,{" "}
        <a href="https://www.visionplaza.nl">Vision Plaza</a>, de{" "}
        <a href="https://oig.nl/">Onderwijs Innovatie Groep</a>,{" "}
        <a href="https://www.floorzorgt.nl/">FloorZorgt</a>, de{" "}
        <a href="https://kortebaanbond.nl/">Kortebaanbond</a>,{" "}
        <a href="https://www.perspectiefopschool.nl/">Perspectief op School</a>{" "}
        en <a href="https://www.spicybusiness.nl/">Spicy Business</a>.
      </p>

      <p>
        Neem gerust <a href="mailto:info@statuscode.nl">contact</a> op voor
        advies over of ontwikkeling van een website, mobiele applicatie, TV
        applicatie of andere digitale toepassing. We houden van een uitdaging en
        zijn gespecialiseerd in <strong>maatwerk voor ieder scherm</strong>.
      </p>
    </article>
  </Layout>
)

export default IndexPage
